<template>
  <div class="menber-dialog">
    <el-dialog
      title="可推送单位"
      :visible.sync="dialogVisible"
      :modal="false"
      :close-on-click-modal="false"
      :append-to-body="true"
      :before-close="handleClose"
      width="1000px"
    >
      <div class="form">
        <FormQuery
          ref="formquery"
          style="margin-left: 30px"
          :data="Query"
          :key="keyForm"
          @submit="search"
          @reset="reset"
          @change="change"
        >
        </FormQuery>
        <el-transfer
          v-model="tuisong"
          :data="tableData"
          :props="props"
          :titles="['农服商列表', '已选农服务商']"
        >
        </el-transfer>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button style="margin-top: 20px" @click="back">取消</el-button>
        <el-button type="primary" @click="submit">推送</el-button>
      </div>
    </el-dialog>
  </div>
</template>
  <script>
import { removeEmptyChildren } from "@/js/utils/util";
import FormQuery from "@/components/form/form-search";
import {getUserName } from "@/api/landTrustStatistics";
import { getXzqArr, orderPush } from "@/api/add";
import { mapGetters, mapState } from "vuex";
const Query = [
  {
    name: "serverCorp",
    label: "作物类型:",
    componentType: "Selects",
    placeholder: "请选择",
    width: "145px",
    dataOptions: [],
  },
  {
    name: "areaCode",
    label: "所在地区：",
    componentType: "cascader",
    placeholder: "请选择",
    width: "145px",
    props: {
      label: "areaName",
      children: "children",
      value: "id",
      checkStrictly: true,
    },
    dataOptions: [],
  },
  {
    name: "companyName",
    label: "农服商:",
    componentType: "input",
    inputType: "",
    placeholder: "",
    width: "145px",
  },
];
export default {
  components: {
    FormQuery,
  },
  props: ["cropCodeArr", "orderObj"],
  data() {
    return {
      tableData: [],
      total: 0,
      tuisong: [],
      query: {},
      Query: Query,
      keyForm: 0,
      dialogVisible: true,
      xczpInfos: [],
      xczpIds: [],
      cqpzInfos: [],
      cqpzIds: [],
      regionOptions: [{}],
      form: {
        serverCorp: [],
      },
      props: {
        key: "authId",   // 在这更改农服商列表数据
        label: "name",
      },
      fileList: [],
      fileList1: [],
      files: [],
      files1: [],
      zypzArr: [],
      tghjArr: [],
      landType: [],
      areaCode: null,
      serverCorp:null
    };
  },
  created() {
    this.getRegions();
    this.getCompanyList();
  },
  computed: mapGetters(["userInfo", "identityObj"]),
  methods: {
    // 搜索框的点击事件
    change(code) {
      console.log("code",code);
      if (code.key === "serverCorp") {
        this.serverCorp = code.value ? code.value.join() : null;
      }
      if (code.key === "areaCode") {
        this.areaCode = code.value;
      }
    },
    // 搜索
    search(params) {
      this.query = {
        areaCode: this.areaCode,
        name: params.companyName,
        serverCorp: this.serverCorp? this.serverCorp:null,
      };
      this.getCompanyList();
    },
    // 重置
    reset() {
      this.areaCode = null;
      this.serverCorp=null;
      this.getCompanyList();
    },
// 获取农服商列表
    async getCompanyList() {
      let res = await getUserName({ ...this.query, type: 0,serverCorp:this.serverCorp?this.serverCorp:null });
      if (res.code == 0) {
        this.tableData = res.data;
        this.total = res.data.length;
      } else {
        this.$message.error("查询失败");
      }
    },
    // 获取地区
    async getRegions() {
      let res = await getXzqArr({
        level: 3,
      });
      if (res.code == 0) {
        // this.$refs.formquery.changeFormItem(0, "dataOptions", res.data);
        let hasRegion = setInterval(() => {
          this.Query[0].dataOptions = this.cropCodeArr;
          this.Query[1].dataOptions = removeEmptyChildren(res.data, "children");
          this.keyForm++;
          clearInterval(hasRegion);
        }, 100);
      }
    },
    // 提交推送
    async submit() {
      console.log("this.tuisong",this.tuisong);
      console.log(" this.tableData", this.tableData);
      if (this.tuisong.length == 0) {
        this.$message.warning("请选择农服商");
      } else {
        let arr = [];
        this.tuisong.forEach((item) => {
          this.tableData.forEach((item2) => {
            if (item2.authId === item) {
              arr.push({
                companyUid: item2.userId,
                companyAuthId: item2.authId,
                orderId: this.orderObj.orderId,
                orderUid: this.orderObj.createBy,
                orderAuthId: this.orderObj.authId,
                crodName: this.identityObj.areaFullName,
                content: JSON.stringify(this.orderObj)  // 订单详细
              });
            }
          });
        });
        let res = await orderPush(arr);
        if (res.code == 0) {
          this.$message.success("推送成功");
          this.$parent.showTS = false;
          this.$parent.getList();
        } else {
          this.$message.error("推送失败");
        }
      }
    },
    back() {
      this.$emit("closeDialog", false);
    },
    handleClose(done) {
      done();
      this.$emit("closeDialog", false);
    },
  },
};
</script>
  <style lang="less" scoped>
/deep/.el-form-item {
  margin-bottom: 20px;
  .el-input {
    width: 160px !important;
  }
}
/deep/.el-dialog__body {
  padding-left: 24px;
}
/deep/.el-transfer-panel__list {
  height: 100%;
}
/deep/.el-transfer-panel__body {
  height: 380px;
}
/deep/.el-checkbox__input.is-checked .el-checkbox__inner,
/deep/.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #182a87 !important;
  border-color: #182a87 !important;
}
/deep/.el-transfer {
  padding-left: 100px;
  padding-top: 8px;
}
/deep/.el-transfer-panel {
  width: 252px !important;
}
.sends-info {
  padding-bottom: 20px;
  text-align: left;
  .form {
    text-align: left;
    // width: 679px;
    margin: 0 auto;
    margin-top: 28px;
    padding: 0 150px 0px 50px;

    .el-radio-group {
      text-align: left;
    }

    .el-radio-button,
    .el-radio-button__inner {
      margin-right: 16px;
      width: 89px;
    }
  }

  .form-item-con {
    height: 50px;
  }
}
</style>
